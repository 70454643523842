import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import OrderedList from "~/components/List/Ordered";
import Button from "~/components/button/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={6}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 6</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>Now that you have read about clinical trials and reviewed aspects of good experimental design, you will look at two real clinical trials.</p>
              <p>Your teacher will assign you to a team of 2-3 students and give you a handout to use.  As a team, you will read about one of the two clinical trials.</p>

              <OrderedList>
                <li>Click on the link below to read about the clinical trial that you have been assigned.</li>
                <li>Your handout lists questions to help you evaluate the quality of the clinical trial. Fill in answers to these questions for the study to which you were assigned. It is OK to leave a box empty if you cannot find an answer in the reading.</li>
                <li>After you read about your assigned clinical trial and answered the questions, you will share what you learned with a team that read about the other clinical trial.</li>
              </OrderedList>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/high-school/lesson-2/step-6a"
                  icon="external"
                  title="Green coffee bean extract study"
                >
                  Green coffee bean extract study
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/high-school/lesson-2/step-6b"
                  icon="external"
                  title="Diabetes Prevention Program (DPP) study"
                >
                  Diabetes Prevention Program (DPP) study
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students work in teams of 2–3 for this part of the lesson.</p>

              <UnorderedList>
                <li className="list-alpha">Give each student a copy of the handout, <em>Evaluating the Clinical Trials.</em></li>
                <li className="list-alpha">Instruct half the teams to read the green coffee bean extract study and the other half of the teams to read the Diabetes Prevention Program (DPP) study.</li>
                <li className="list-alpha">Explain that they should use what they learned from the reading to fill in the blanks on their handouts. (Tell students it is OK if they cannot fill in a blank because information is not included in their reading.)</li>
                <li className="list-alpha">After teams have completed their readings, have them meet with a group that had the other study to compare their answers (and fill in the blanks on the handout for the other study).</li>
              </UnorderedList>

              <p>If students are having trouble understanding the results of the studies, have them focus on information related more closely to the design of the studies. (However, some students may notice that some of the results of the green coffee bean extract study are unexpected, i.e., more weight loss during a washout period or during the placebo phase than during the low- or high-dose phases.)</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/files/hs/lesson_2/handout_Evaluating_the_Clinical_Trials-scl.final.aw.pdf"
                  icon="download"
                  title="Handout: Evaluating the Clinical Trials"
                >
                  Handout: Evaluating the Clinical Trials
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/files/hs/lesson_2/Sample_answers_evaluating_clinical_trials-scl,final.aw.pdf"
                  icon="download"
                  title="Sample answers to Evaluating the Clinical Trials"
                >
                  Sample answers to Evaluating the Clinical Trials
                </Button>
              </div>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
